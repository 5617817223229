import { useEffect, useRef, useState } from "react";




function App() {
  const iframeRef = useRef()
  const [detail, setDetail] = useState(null)

  const getData = async () => {
    const response = await fetch("https://panel.game4study.com/api/v1/meta/chat-dream");
    const res = await response.json();
    if (res?.api_list?.succeeded == true) {
      setDetail(res?.api_list?.data);
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (detail && detail != null) {
      iframeRef.current.src = detail?.chat;
      iframeRef.current.src = iframeRef.current.src
    }
  }, [detail])


  return (
    <div className="main" style={{ height: '100svh' }}>
      <iframe ref={iframeRef} src={detail?.chat} />
    </div>
  );
}

export default App;
